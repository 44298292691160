

































































































import IndexContact from "@/components/ContactData/IndexContact.vue";
import useBlob from "@/hooks/useBlob";
import MNotificationVue from "@/mixins/MNotification.vue";
import { DATE_TIME_HOURS_DEFAULT_FORMAT } from "@/models/constants/date.constant";
import { HeaderListContactDataDto } from "@/models/interface/contact-data/HeaderListContactDataDto.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { contactServices } from "@/services/contact.service";
import moment from "moment";
import Vue from "vue";

export default Vue.extend({
  name: "IndexPage",
  components: {
    IndexContact,
  },
  mixins: [MNotificationVue],
  data() {
    return {
      state: {
        search: this.$route.query.q ?? "",
      },
      currentTab: (this.$route.query.tab ?? "customer") as
        | "customer"
        | "supplier"
        | "employee",
      loading: {
        download: false,
        sync: false,
      },
      lastSync: moment(),
    };
  },
  computed: {
    isCustomer() {
      return this.currentTab === "customer";
    },
    lastSyncTitle() {
      const date = this.lastSync.format(DATE_TIME_HOURS_DEFAULT_FORMAT);
      return this.$t("lbl_last_synchronize_text", { text: date });
    },
  },
  methods: {
    onFetchDone(response: HeaderListContactDataDto) {
      this.lastSync = response.lastSyncDate
        ? moment(response.lastSyncDate)
        : moment();
    },
    reset() {
      this.state.search = "";
    },
    handleDownload() {
      const { toDownload } = useBlob();
      const params: RequestQueryParamsModel = {
        search: `${this.currentTab}~true`,
      };
      this.loading.download = true;
      contactServices
        .downloadReportContact(params)
        .then(dataBlob => {
          toDownload(dataBlob, "report_contact.xlsx");
        })
        .finally(() => (this.loading.download = false));
    },
    onChangeTab(e) {
      this.$router.replace({
        name: "settings.contact.list",
        query: {
          ...this.$route.query,
          tab: e.target.value,
        },
      });
    },
    async sync() {
      try {
        this.loading.sync = true;
        await contactServices.syncAllContact();
        this.lastSync = moment();
        this.showNotifSuccess("lbl_sync_success");
      } finally {
        this.loading.sync = false;
      }
    },
  },
});
