var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_stock_adjustment") } },
    [
      _c(
        "a-form",
        {
          attrs: {
            form: _vm.form,
            "wrapper-col": { span: 10 },
            "label-col": { span: 6 }
          },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.no.label),
                        "label-align": "left"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.no.decorator,
                              expression: "formRules.no.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.no.name,
                            placeholder: _vm.$t(_vm.formRules.no.placeholder),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loadingNo,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getStockAdjustment(value)
                            },
                            change: _vm.handleStockAdjustment
                          }
                        },
                        _vm._l(_vm.dataStockAdjustment, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " + _vm._s(data.documentNumber) + " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(data.documentNumber) + " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.branch.label),
                        "label-align": "left"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.branch.decorator,
                              expression: "formRules.branch.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.branch.name,
                            placeholder: _vm.$t(
                              _vm.formRules.branch.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loadingBranch,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getBranch(value)
                            },
                            change: _vm.handleBranch
                          }
                        },
                        _vm._l(_vm.dataBranch, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.warehouse.label),
                        "label-align": "left"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.warehouse.decorator,
                              expression: "formRules.warehouse.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.warehouse.name,
                            placeholder: _vm.$t(
                              _vm.formRules.warehouse.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loadingLocationWarehouse,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListWarehouse(value, _vm.branchId)
                            },
                            change: _vm.handleChangeWarehouse
                          }
                        },
                        _vm._l(_vm.dataListLocationWarehouse, function(
                          data,
                          index
                        ) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.rack.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.rack.decorator,
                              expression: "formRules.rack.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.rack.name,
                            placeholder: _vm.$t(_vm.formRules.rack.placeholder),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loadingLocationRack,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListLocationRack(
                                value,
                                _vm.idWarehouse
                              )
                            }
                          }
                        },
                        _vm._l(_vm.dataListLocationRack, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.adj.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.adj.decorator,
                              expression: "formRules.adj.decorator"
                            }
                          ],
                          attrs: {
                            placeholder: _vm.$t(_vm.formRules.adj.placeholder),
                            allowClear: true
                          },
                          on: { change: _vm.handleSelectType }
                        },
                        _vm._l(_vm.optType, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.key } },
                            [_vm._v(" " + _vm._s(item.key) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.date.label) } },
                    [
                      _c("a-range-picker", {
                        attrs: {
                          ranges: {
                            Today: [_vm.moment(), _vm.moment()],
                            "This Month": [
                              _vm.moment(),
                              _vm.moment().endOf("month")
                            ]
                          },
                          format: _vm.DEFAULT_DATE_FORMAT,
                          type: "date"
                        },
                        on: { change: _vm.onRangePickerChange },
                        model: {
                          value: _vm.modelForm.date,
                          callback: function($$v) {
                            _vm.$set(_vm.modelForm, "date", $$v)
                          },
                          expression: "modelForm.date"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleCancel } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: { loading: _vm.loadingTable, type: "primary" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.$can("create", "stock-adjustment")
                ? _c(
                    "a-col",
                    { attrs: { span: 12, align: "end" } },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "logistic.stock-adjustment.create"
                            }
                          }
                        },
                        [
                          _c("a-button", { attrs: { type: "primary" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("lbl_create_new")) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          "data-source": _vm.dataSource,
          columns: _vm.columnsTable,
          loading: _vm.loadingTable,
          pagination: {
            showTotal: function() {
              return _vm.$t("lbl_total_items", { total: _vm.totalElements })
            },
            total: _vm.totalElements,
            showSizeChanger: true,
            current: _vm.page
          },
          scroll: { x: 700 },
          size: "small"
        },
        on: { change: _vm.onChangeTable },
        scopedSlots: _vm._u([
          {
            key: "number",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("qty")(text)))])
            }
          },
          {
            key: "date",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("date")(text)))])
            }
          },
          {
            key: "nullable",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
            }
          },
          {
            key: "journal",
            fn: function(text, record) {
              return [
                record.journalNumber
                  ? _c(
                      "a-button",
                      {
                        staticClass: "p-0",
                        attrs: { type: "link" },
                        on: {
                          click: function($event) {
                            return _vm.getJournal(record.journalNumber)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(record.journalNumber) + " ")]
                    )
                  : _c("span", [_vm._v(_vm._s("-"))])
              ]
            }
          },
          {
            key: "operation",
            fn: function(text, record) {
              return [
                _c(
                  "a-dropdown",
                  { attrs: { trigger: ["click"], placement: "bottomCenter" } },
                  [
                    _c("a-button", {
                      attrs: { icon: "bars" },
                      on: {
                        click: function(e) {
                          return e.preventDefault()
                        }
                      }
                    }),
                    _c(
                      "a-menu",
                      {
                        staticClass: "mt-2",
                        attrs: { slot: "overlay" },
                        slot: "overlay"
                      },
                      [
                        _vm.allowEdit(record.state) &&
                        _vm.$can("update", "stock-adjustment")
                          ? [
                              _c(
                                "a-menu-item",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name:
                                            "logistic.stock-adjustment.edit",
                                          params: { id: record.id }
                                        }
                                      }
                                    },
                                    [
                                      _c("a-icon", { attrs: { type: "edit" } }),
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("lbl_edit")) + " "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          : [
                              _c(
                                "a-menu-item",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name:
                                            "logistic.stock-adjustment.detail",
                                          params: { id: record.id }
                                        }
                                      }
                                    },
                                    [
                                      _c("a-icon", { attrs: { type: "eye" } }),
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("lbl_view")) + " "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                      ],
                      2
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "mt-2 text-right" },
        [
          _c(
            "a-space",
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingDownload },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              ),
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.loadingPrint,
                    icon: "printer"
                  },
                  on: { click: _vm.handlePrint }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }